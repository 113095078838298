import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-naslovna',
  templateUrl: './naslovna.component.html',
  styleUrls: ['./naslovna.component.css'],
})
export class NaslovnaComponent implements OnInit {

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  bookingEmailTekst: string = '';
  bookingValidationMsg: string = '';

  bookingForm = new FormGroup({
    imePrezime: new FormControl(''),
    email: new FormControl(''),
    tel: new FormControl(''),
    apartman: new FormControl(this.translate.instant('spisak_apartmana.marina_2'))
  });

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService,
    calendar: NgbCalendar) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
      this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  onBookingFormSubmit() {
    let valid = true;
    this.bookingValidationMsg = '';

    if (localStorage.getItem('lang') === 'sr') {
      if (!this.bookingForm.value.imePrezime) {
        this.bookingValidationMsg = "Ime i prezime je obavezno polje";
        valid = false;
      }
      if (!this.bookingForm.value.email) {
        this.bookingValidationMsg = "Email adresa je obavezno polje";
        valid = false;
      }
      if (!this.bookingForm.value.tel) {
        this.bookingValidationMsg = "Broj telefona je obavezno polje";
        valid = false;
      }
      if (!this.fromDate || !this.toDate) {
        this.bookingValidationMsg = "Početni i krajnji datum rezervacije mora biti unešen";
        valid = false;
      }
      if (valid) {
        if (this.toDate) {
          let msgBody = "Poštovani, \n \n"+
                        "Korisnik " + this.bookingForm.value.imePrezime + " želi da bukira apartman ''" + this.bookingForm.value.apartman + 
                        "'' u periodu od " + this.fromDate.day + "." + this.fromDate.month + "." + this.fromDate.year + " do " +
                        this.toDate.day + "." + this.toDate.month + "." + this.toDate.year + " \n \n" +
                        "Informacije o korisniku: \n" +
                        "Ime i prezime: " + this.bookingForm.value.imePrezime + " \n" +
                        "Email: " + this.bookingForm.value.email + " \n" +
                        "Telefon: " + this.bookingForm.value.tel + " \n";
    
          this.bookingEmailTekst = "mailto:info@koprent.com?"+
                                 "subject=Booking::" + this.bookingForm.value.imePrezime+
                                 "&body=" + encodeURIComponent(msgBody);
          window.location.href = this.bookingEmailTekst;

          this.bookingForm.controls.imePrezime.patchValue('');
          this.bookingForm.controls.email.patchValue('');
          this.bookingForm.controls.tel.patchValue('');
          this.bookingForm.controls.apartman.patchValue('Marina 2 – Apartman za 4-6 osoba (60m2)');
        }
      }
    } else if (localStorage.getItem('lang') === 'en') {
      if (!this.bookingForm.value.imePrezime) {
        this.bookingValidationMsg = "Name and last name are required";
        valid = false;
      }
      if (!this.bookingForm.value.email) {
        this.bookingValidationMsg = "Email address is required";
        valid = false;
      }
      if (!this.bookingForm.value.tel) {
        this.bookingValidationMsg = "Phone number is required";
        valid = false;
      }
      if (!this.fromDate || !this.toDate) {
        this.bookingValidationMsg = "Booking start and end dates are required";
        valid = false;
      }
      if (valid) {
        if (this.toDate) {
          let msgBody = "Dear, \n \n"+
                        "User " + this.bookingForm.value.imePrezime + " wants to book an apartment ''" + this.bookingForm.value.apartman + 
                        "'' between " + this.fromDate.day + "." + this.fromDate.month + "." + this.fromDate.year + " and " +
                        this.toDate.day + "." + this.toDate.month + "." + this.toDate.year + " \n \n" +
                        "User information: \n" +
                        "First and last name: " + this.bookingForm.value.imePrezime + " \n" +
                        "Email address: " + this.bookingForm.value.email + " \n" +
                        "Phone number: " + this.bookingForm.value.tel + " \n";
    
          this.bookingEmailTekst = "mailto:info@koprent.com?"+
                                 "subject=Booking::" + this.bookingForm.value.imePrezime+
                                 "&body=" + encodeURIComponent(msgBody);
          window.location.href = this.bookingEmailTekst;  

          this.bookingForm.controls.imePrezime.patchValue('');
          this.bookingForm.controls.email.patchValue('');
          this.bookingForm.controls.tel.patchValue('');
          this.bookingForm.controls.apartman.patchValue('Marina 2 – Apartman za 4-6 osoba (60m2)');
        }
      }
    }

    
  }

}
