import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';
import {ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kopaonik',
  templateUrl: './kopaonik.component.html',
  styleUrls: ['./kopaonik.component.css'],
})
export class KopaonikComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private cd : ChangeDetectorRef,
    private router: Router) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
  }
}
