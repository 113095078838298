import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { KopaonikComponent } from './pages/kopaonik/kopaonik.component';
import { CenovnikComponent } from './pages/cenovnik/cenovnik.component';
import { Jana5Component } from './pages/jana_5/jana_5.component';
import { Jana4Component } from './pages/jana_4/jana_4.component';
import { Marina28Component } from './pages/marina_28/marina_28.component';
import { Marina27Component } from './pages/marina_27/marina_27.component';
import { Marina26Component } from './pages/marina_26/marina_26.component';
import { Marina25Component } from './pages/marina_25/marina_25.component';
import { Marina2Component } from './pages/marina_2/marina_2.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { NaslovnaComponent } from './pages/naslovna/naslovna.component';
import { FooterComponent } from './shared/footer/footer.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { ApartmaniComponent } from './pages/apartmani/apartmani.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NaslovnaComponent,
    ApartmaniComponent,
    Marina2Component,
    Marina25Component,
    Marina26Component,
    Marina27Component,
    Marina28Component,
    Jana4Component,
    Jana5Component,
    CenovnikComponent,
    KopaonikComponent,
    KontaktComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    NgxGalleryModule
  ],
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent] 
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
