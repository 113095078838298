import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-marina_28',
  templateUrl: './marina_28.component.html',
  styleUrls: ['./marina_28.component.css'],
})
export class Marina28Component implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
          width: '100%',
          height: '800px',
          thumbnailsColumns: 6,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      {
          breakpoint: 400,
          preview: false
      }
    ];

    this.galleryImages = [
{
 small: '/assets/images/koprent_slike/marina_28_studio/PPH_9540.jpg',
medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9540.jpg',
   big: '/assets/images/koprent_slike/marina_28_studio/PPH_9540.jpg'
},
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9551.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9551.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9551.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9558.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9558.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9558.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9560.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9560.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9560.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9563.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9563.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9563.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9565.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9565.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9565.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9567.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9567.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9567.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9568.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9568.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9568.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_28_studio/PPH_9572.jpg',
 medium: '/assets/images/koprent_slike/marina_28_studio/PPH_9572.jpg',
    big: '/assets/images/koprent_slike/marina_28_studio/PPH_9572.jpg'
 },
      
    ];
  }
}
