import { CenovnikComponent } from './pages/cenovnik/cenovnik.component';
import { Marina2Component } from './pages/marina_2/marina_2.component';
import { ApartmaniComponent } from './pages/apartmani/apartmani.component';
import { AppComponent } from './app.component';
import { NaslovnaComponent } from './pages/naslovna/naslovna.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Marina25Component } from './pages/marina_25/marina_25.component';
import { Marina26Component } from './pages/marina_26/marina_26.component';
import { Marina27Component } from './pages/marina_27/marina_27.component';
import { Marina28Component } from './pages/marina_28/marina_28.component';
import { Jana4Component } from './pages/jana_4/jana_4.component';
import { Jana5Component } from './pages/jana_5/jana_5.component';
import { KopaonikComponent } from './pages/kopaonik/kopaonik.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';

const routes: Routes = [
  {
    path: '', component: NaslovnaComponent
  },
  {
    path: 'apartmani', component: ApartmaniComponent
  },
  {
    path: 'apartmani/marina_2', component: Marina2Component
  },
  {
    path: 'apartmani/marina_25', component: Marina25Component
  },
  {
    path: 'apartmani/marina_26', component: Marina26Component
  },
  {
    path: 'apartmani/marina_27', component: Marina27Component
  },
  {
    path: 'apartmani/marina_28', component: Marina28Component
  },
  {
    path: 'apartmani/jana_4', component: Jana4Component
  },
  {
    path: 'apartmani/jana_5', component: Jana5Component
  },
  {
    path: 'cenovnik', component: CenovnikComponent
  },
  {
    path: 'kopaonik', component: KopaonikComponent
  },
  {
    path: 'kontakt', component: KontaktComponent
  },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
