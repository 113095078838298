import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-marina_25',
  templateUrl: './marina_25.component.html',
  styleUrls: ['./marina_25.component.css'],
})
export class Marina25Component implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
          width: '100%',
          height: '800px',
          thumbnailsColumns: 6,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      {
          breakpoint: 400,
          preview: false
      }
    ];

    this.galleryImages = [
{
 small: '/assets/images/koprent_slike/marina_25/CA9A8224.jpg',
medium: '/assets/images/koprent_slike/marina_25/CA9A8224.jpg',
   big: '/assets/images/koprent_slike/marina_25/CA9A8224.jpg'
},
{
  small: '/assets/images/koprent_slike/marina_25/CA9A8225.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8225.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8225.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8227.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8227.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8227.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8229.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8229.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8229.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8231.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8231.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8231.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8232.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8232.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8232.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8235.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8235.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8235.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8237.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8237.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8237.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/CA9A8239.jpg',
 medium: '/assets/images/koprent_slike/marina_25/CA9A8239.jpg',
    big: '/assets/images/koprent_slike/marina_25/CA9A8239.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9681.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9681.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9681.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9699.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9699.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9699.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9715.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9715.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9715.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9725.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9725.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9725.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9726.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9726.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9726.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9728.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9728.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9728.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9730.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9730.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9730.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9745.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9745.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9745.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9756.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9756.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9756.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9760.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9760.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9760.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_25/PPH_9762.jpg',
 medium: '/assets/images/koprent_slike/marina_25/PPH_9762.jpg',
    big: '/assets/images/koprent_slike/marina_25/PPH_9762.jpg'
 },
    ];
  }
}
