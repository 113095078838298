<main>
    <section class="swip h3-about-section">
       <div class="container-fluid section-menu">
          <div class="row">
             <div class="col-md-8  wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                <div class="home-about-3" style="background-color: rgba(255,255,255, 0.6); padding-bottom: 32px;">
                   <div class="thm-h thm-h-3 ">
                      <h2 class=" thm-h-3-big" data-paroller-factor="-0.18" data-paroller-type="foreground" data-paroller-direction="vertical" >{{ 'apartmani.header_title_pale' | translate }}</h2>
                      <h4>{{ 'apartmani.header_title' | translate }}</h4>
                   </div>
                   <p>
                    {{ 'apartmani.header_tekst_1' | translate }}<br>
                    {{ 'apartmani.header_tekst_2' | translate }}<br>
                    {{ 'apartmani.header_tekst_3' | translate }}<br>
                   </p>
                </div>
             </div>
             <div class="col-md-3">
             </div>
          </div>
       </div>
    </section>
    <section class="main-section explore-section " >
       <div class="container-fluid">
          <div class="row" style="margin-bottom: 32px;">
             
            <div class="col-md-6 p-0">
                <div class="explore_box exb-1 exb-type-1">
                   <a href="#/apartmani/marina_2">
                      <img class="img-fluid" src="/assets/images/koprent_slike/marina_2/apartmani_01.png">
                      <div class="thm-h thm-h-3 ">
                         <h4>Marina 2</h4>
                      </div>
                   </a>
                </div>
             </div>
             <div class="col-md-6 p-0">
                <div class="explore_box exb-type-2">
                   <a href="#/apartmani/marina_25">
                      <img class="img-fluid" src="/assets/images/koprent_slike/marina_25/apartmani_01.png">
                      <img class="img-fluid-i" src="/assets/images/koprent_slike/marina_25/apartmani_01.png">
                      <div class="thm-h thm-h-3 text-center">
                         <h4 style="color: #fff;">Marina 25</h4>
                      </div>
                   </a>
                </div>
             </div>

             <div class="col-md-6 p-0">
                <div class="explore_box exb-type-2">
                   <a href="#/apartmani/marina_26">
                      <img class="img-fluid" src="/assets/images/koprent_slike/marina_26/apartmani_01.png">
                      <img class="img-fluid-i" src="/assets/images/koprent_slike/marina_26/apartmani_01.png">  
                      <div class="thm-h thm-h-3 text-center">
                         <h4 style="color: #fff;">Marina 26</h4>
                      </div>
                   </a>
                </div>
             </div>
             <div class="col-md-6 p-0">
                <div class="explore_box exb-1 exb-type-1">
                   <a href="#/apartmani/marina_27">
                      <img class="img-fluid" src="assets/images/koprent_slike/marina_26/CA9A8241.jpg">
                      <div class="thm-h thm-h-3 ">
                         <h4>Marina 27</h4>
                      </div>
                   </a>
                </div>
             </div>

             <div class="col-md-6 p-0">
                <div class="explore_box exb-1 exb-type-1">
                   <a href="#/apartmani/marina_28">
                      <img class="img-fluid" src="/assets/images/koprent_slike/marina_28_studio/apartman_bg.jpg">
                      <div class="thm-h thm-h-3 ">
                         <h4>Marina 28</h4>
                      </div>
                   </a>
                </div>
             </div>
             <div class="col-md-6 p-0">
                <div class="explore_box exb-type-2">
                   <a href="#/apartmani/jana_4">
                      <img class="img-fluid" src="/assets/images/koprent_slike/jana_4-5/apartmani_01.png">
                      <img class="img-fluid-i" src="/assets/images/koprent_slike/jana_4-5/apartmani_01.png">
                      <div class="thm-h thm-h-3 text-center">
                         <h4 style="color: #fff;">Jana 4</h4>
                      </div>
                   </a>
                </div>
             </div>

             <div class="col-md-12 p-0">
                <div class="explore_box exb-1 exb-type-1">
                   <a href="#/apartmani/jana_5">
                      <img class="img-fluid" src="/assets/images/koprent_slike/jana_4-5/apartmani_02.png">
                      <div class="thm-h thm-h-3 ">
                         <h4>Jana 5</h4>
                      </div>
                   </a>
                </div>
             </div>
             
          </div>
       </div>
    </section>
 </main>