<footer class="footerdark footer-1 swip">
    <section id="h-newslatter" class="h-newslatter main-section no-a-b ">
       <div class="container-fluid">
          <div class="row m-0">
             <div class="col-md-4">
                <div class="footer-box">
                   <h2 class="wow fadeInUp" data-wow-delay="1s">{{ 'footer.kontakt' | translate }}</h2>
                   <div class="textwidget">
                      <p><a href="tel:+381605990933">+381 60 599 0 933</a><br>
                         <a href="mailto:info@koprent.com">info@koprent.com</a>
                      </p>
                   </div>
                </div>
             </div>
             <div class="col-md-4">
                <div class="footer-box">
                   <h2 class="wow fadeInUp" data-wow-delay="1s">{{ 'footer.info_o_firmi' | translate }}</h2>
                   <div class="textwidget">
                     <p>
                        {{ 'kontakt.info_1' | translate }}<br>
                        {{ 'kontakt.info_2' | translate }}<br>
                        {{ 'kontakt.info_3' | translate }}<br>
                        {{ 'kontakt.info_4' | translate }}<br>
                        {{ 'kontakt.info_5' | translate }}<br>
                        {{ 'kontakt.info_6' | translate }}<br>
                      </p>
                   </div>
                </div>
             </div>
             
             <div class="col-md-4">
                <div class="h-newslatter-inner footer-box">
                   <div class="thm-h  sec-main-h">
                      <h2 class="wow fadeInUp" data-wow-delay="1s">{{ 'footer.postavi_pitanje' | translate }}</h2>
                   </div>
                   <form [formGroup]="pitanjeForm" (ngSubmit)="onPitanjeFormSubmit()" class="form-inline text-center justify-content-center thm-form wow fadeInUp"  data-wow-duration="1s" >
                      <div class="field">
                         <input formControlName="imePrezime" class="form-control " type="search" aria-label="Search" placeholder="{{ 'footer.postavi_pitanje_ime_prezime' | translate }}">
                      </div>
                      <div class="field" style="margin-top: 16px;">
                        <textarea formControlName="poruka" class="form-control " rows="2" type="search" aria-label="Search" placeholder="{{ 'bukiranje.poruka' | translate }}"></textarea>
                     </div>
                     <label *ngIf="pitanjeValidationMsg" style="color: tomato; margin: 16px;">{{ pitanjeValidationMsg }}</label>
                      <div class="submit_news">
                         <input type="submit" value="">
                         <span class="submit-arrow">
                         <img src="assets/images/ap.svg">
                         </span>
                      </div>
                   </form>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section id="" class="footermain main-section dark no-a-b pb-1 pt-1 ">
       <div class="container-fluid">
          <div class="row m-0">
             <div  class="col-sm-12">
                <div class="row mb-3 mt-3">
                   <ul class="social p-0 col-sm-4 text-center " >
                      <li>
                         <div class="dropdown ">
                           <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                              {{ 'footer.promeni_jezik' | translate }}
                           </button>
                            <div class="dropdown-menu">
                               <span class="dropdown-item" style="color: rgba(255, 255, 255, .6)!important;" (click)="promeniJezik('sr')">{{ 'header.jezik_sr' | translate }}</span>
                               <span class="dropdown-item" style="color: rgba(255, 255, 255, .6)!important;" (click)="promeniJezik('en')">{{ 'header.jezik_en' | translate }}</span>
                            </div>
                         </div>
                      </li>
                   </ul>
                   <ul class="social p-0 col-sm-4 text-center mt-3" >
                      <li>
                         <a href="https://www.facebook.com/kop.rent.98/?jazoest=24896">
                         <i class="fab fa-facebook-f"></i>
                         </a>
                      </li>
                      <li>
                         <a href="https://www.instagram.com/apartmani_koprent/">
                         <i class="fab fa-instagram"></i>
                         </a>
                      </li>
                   </ul>
                </div>
                <div class="footer-logo mt-3 mb-3">
                   <a class="navbar-brand text-center p1" href="#" >
                   <img  src="assets/images/logo.png">
                   </a>
                </div>
             </div>
          </div>
       </div>
    </section>
 </footer>