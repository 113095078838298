<main>
   <div class="header-home-2">
      <div class="container-fluid">
         <div class="header-cont">
            <h1 class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">{{ 'header.header_img_title_1' | translate }}</h1>
            <h5 class="wow fadeInUp" data-wow-duration="1s" class="wow fadeInUp" >{{ 'header.header_img_title_2' | translate }}</h5>
         </div>
       </div>
   </div>
    <!-- Bukiranje -->
    <section class="swip h2-book-section">
       <div class="container-fluid section-menu ">
          <div class="row">
             <div class="col-md-5 home-about wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                <div class="thm-h ">
                   <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'naslovna_info.title' | translate }}</h2>
                   <h4>{{ 'naslovna_info.subtitle' | translate }}</h4>
                </div>
                <p>{{ 'naslovna_info.tekst' | translate }}</p>
                <p>{{ 'naslovna_info.tekst_2' | translate }}</p>
             </div>
             <div class="col-md-3">
             </div>
             <div class="col-md-4  wow fadeInUp">
                <div class="home-booknow" style="margin-top: 0; padding-top: 0;">
                   <div class="booknow_box" style="margin-top: 0;">
                      <h4>{{ 'bukiranje.bukiranje_title' | translate }}</h4>
                      <form [formGroup]="bookingForm" (ngSubmit)="onBookingFormSubmit()">
                         <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{ 'bukiranje.ime' | translate }}" formControlName="imePrezime">
                         </div>
                         <div class="form-group">
                            <input type="email" class="form-control" placeholder="{{ 'bukiranje.email' | translate }}" formControlName="email">
                         </div>
                         <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{ 'bukiranje.telefon' | translate }}" formControlName="tel">
                         </div>
                         <div class="form-group">
                           <label style="margin-left: 16px; color: #c5eaef;">{{ 'bukiranje.apartman' | translate }}:</label>
                           <select id="booking_apartman" class="form-control" formControlName="apartman">
                              <option checked>{{ 'spisak_apartmana.marina_2' | translate }}</option>
                              <option>{{ 'spisak_apartmana.marina_25' | translate }}</option>
                              <option>{{ 'spisak_apartmana.marina_26' | translate }}</option>
                              <option>{{ 'spisak_apartmana.marina_27' | translate }}</option>
                              <option>{{ 'spisak_apartmana.marina_28' | translate }}</option>
                              <option>{{ 'spisak_apartmana.jana_4' | translate }}</option>
                              <option>{{ 'spisak_apartmana.jana_5' | translate }}</option>
                           </select>
                        </div>
                        <div class="form-con">
                           <label style="margin-left: 16px; color: #c5eaef;">{{ 'bukiranje.bukiram_dane' | translate }}</label>

                           <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" style="margin-left: 16px; background-color: #fff;">
                           </ngb-datepicker>
                           
                           <ng-template #t let-date let-focused="focused">
                             <span class="custom-day"
                                   [class.focused]="focused"
                                   [class.range]="isRange(date)"
                                   [class.faded]="isHovered(date) || isInside(date)"
                                   (mouseenter)="hoveredDate = date"
                                   (mouseleave)="hoveredDate = null">
                               {{ date.day }}
                             </span>
                           </ng-template>
                        </div>
                        <label *ngIf="bookingValidationMsg" style="color: tomato; margin: 16px;">{{ bookingValidationMsg }}</label>
                         <button type="submit" class="btn btn-primary" style="margin-top: 16px;">{{ 'bukiranje.posalji' | translate }}</button>
                      </form>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    
    <!-- Detalji sa slikama -->
    <section id="h-our-history-2" class="half-section main-section h-our-history-3 swip" style="background-image: url('/assets/images/kopaonik_bg_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row ">
             <div class="col-sm-6 half-left jumbotron" style="background: url('/assets/images/koprent_slike/marina_2/IMG-20210105-WA0020 - Copy.jpg') no-repeat center;
                background-size: cover;"
                data-paroller-factor="-0.3"
                data-paroller-factor-xs="0.2" >
             </div>
             <div class="col-sm-6 half-right">
                <div class="writing-sec my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="horizontal">
                   <div class="thm-h">
                      <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'naslovna_apartmani.title' | translate }}</h2>
                      <h4>{{ 'naslovna_apartmani.subtitle' | translate }}</h4>
                   </div>
                   <p class="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                     {{ 'naslovna_apartmani.tekst' | translate }} 
                   </p>
                  <a href="#/apartmani" class="read-more button-fancy -color-1 wow fadeInUp" data-wow-duration="1s">
                      <span class="btn-arrow"></span>
                      <span class="twp-read-more text">{{ 'naslovna_apartmani.dugme' | translate }}</span>
                  </a>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section id="h-buy" class="half-section main-section swip" style="background-image: url('/assets/images/kopaonik_bg_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row m-0">
             <div class="col-sm-5 half-left">
                <div class="img-sec  mega my-element"
                   data-paroller-factor="0.05"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal" >
                   <img class="img-fluid" src="/assets/images/koprent_slike/marina_25/PPH_9715.jpg">
                </div>
             </div>
             <div class="col-sm-7 half-right">
                <div class="writing-sec mega my-element"
                   data-paroller-factor="-0.2"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal">
                   <div class="thm-h">
                      <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'naslovna_cenovnik.title' | translate }}</h2>
                   </div>
                   <p class="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                     {{ 'naslovna_cenovnik.tekst' | translate }} <br>
                     {{ 'naslovna_cenovnik.tekst_2' | translate }} <br> 
                     {{ 'naslovna_cenovnik.tekst_3' | translate }} <br>
                     {{ 'naslovna_cenovnik.tekst_4' | translate }} <br>
                   </p>
                   <a href="#/cenovnik" class="read-more button-fancy -color-1 wow fadeInUp" data-wow-duration="1s"><span class="btn-arrow"></span><span class="twp-read-more text">{{ 'naslovna_cenovnik.dugme' | translate }}</span></a>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section id="h-sell" class="half-section main-section swip" style="background-image: url('/assets/images/kopaonik_bg_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row m-0 rtl-sec">
             <div class="col-sm-5 half-right">
                <div class="img-sec  mega my-element"
                   data-paroller-factor="0.03"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal">
                   <img class="img-fluid" src="/assets/images/koprent_slike/vikend_naselje/P1280172.jpg">
                </div>
             </div>
             <div class="col-sm-7 half-left ">
                <div class="writing-sec mega my-element"
                   data-paroller-factor="-0.05"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal" >
                   <div class="thm-h">
                      <h2>{{ 'naslovna_kopaonik.title' | translate }}</h2>
                   </div>
                   <p>
                      {{ 'naslovna_kopaonik.tekst' | translate }} <br>
                      {{ 'naslovna_kopaonik.tekst_2' | translate }}
                   </p>
                   <a href="#/kopaonik" class="read-more button-fancy -color-1"><span class="btn-arrow"></span><span class="twp-read-more text">{{ 'naslovna_kopaonik.dugme' | translate }}</span></a>
                </div>
             </div>
          </div>
       </div>
    </section>

    <!-- Apartmani lista -->
    <section id="h-charter" class="main-section h-charter-sec swip " style="background-image: url('/assets/images/snow_abstract_bg.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row m-0 ">
             <div class="thm-h sec-main-h">
                <h2 class="wow fadeInUp" data-wow-duration="1s" >{{ 'naslovna_apartmani_spisak.title' | translate }}</h2>
             </div>
             <div class="col-sm-12"  >
                <div class="owl-carousel owl-carousel-3 center-carousel owl-theme">
                   <div class="item">
                      <a  href="#/apartmani/marina_2">
                         <div class="main-tilt">
                            <div>
                               <img class="main-back" src="/assets/images/koprent_slike/marina_2/500_500.png" alt="">
                            </div>
                            <div   class="inner-tilt">
                               <img class="inner-img" src="/assets/images/koprent_slike/marina_2/500_500.png" alt="">
                               <div class="slide-cont">
                                  <div>
                                     <h3>{{ 'naslovna_apartmani_spisak.apartman_1_title' | translate }}
                                     </h3>
                                     <p>{{ 'naslovna_apartmani_spisak.apartman_1_opis' | translate }}</p>
                                     <span></span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </a>
                   </div>
                   <div class="item">
                      <a  href="#/apartmani/marina_25">
                         <div class="main-tilt">
                            <div data-speed="0.2">
                               <img class="main-back" src="/assets/images/koprent_slike/marina_25/500_5.png" alt="">
                            </div>
                            <div data-speed="0.5" class="inner-tilt">
                               <img class="inner-img" src="/assets/images/koprent_slike/marina_25/500_5.png" alt="">
                               <div class="slide-cont">
                                  <div>
                                    <h3>{{ 'naslovna_apartmani_spisak.apartman_2_title' | translate  }}
                                    </h3>
                                    <p>{{ 'naslovna_apartmani_spisak.apartman_2_opis' | translate  }}</p>
                                    <span></span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </a>
                   </div>
                   <div class="item">
                      <a  href="#/apartmani/marina_26">
                         <div class="main-tilt">
                            <img class="main-back" src="/assets/images/koprent_slike/marina_26/500_500.png" alt="">
                            <div class="inner-tilt">
                               <img class="inner-img" src="/assets/images/koprent_slike/marina_26/500_500.png" alt="">
                               <div class="slide-cont">
                                  <div>
                                    <h3>{{ 'naslovna_apartmani_spisak.apartman_3_title' | translate  }}
                                    </h3>
                                    <p>{{ 'naslovna_apartmani_spisak.apartman_3_opis' | translate  }}</p>
                                    <span></span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </a>
                   </div>
                   <div class="item">
                      <a  href="#/apartmani/marina_27">
                         <div class="main-tilt">
                            <img class="main-back" src="/assets/images/koprent_slike/marina_28_studio/500_500.png" alt="">
                            <div class="inner-tilt">
                               <img class="inner-img" src="/assets/images/koprent_slike/marina_28_studio/500_500.png" alt="">
                               <div class="slide-cont">
                                  <div>
                                    <h3>{{ 'naslovna_apartmani_spisak.apartman_4_title' | translate  }}
                                    </h3>
                                    <p>{{ 'naslovna_apartmani_spisak.apartman_4_opis' | translate  }}</p>
                                    <span></span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </a>
                   </div>
                   <div class="item">
                      <a  href="#/apartmani/marina_28">
                         <div class="main-tilt">
                            <img class="main-back" src="/assets/images/koprent_slike/marina_28_studio/500_500_2.png" alt="">
                            <div class="inner-tilt">
                               <img class="inner-img" src="/assets/images/koprent_slike/marina_28_studio/500_500_2.png" alt="">
                               <div class="slide-cont">
                                  <div>
                                    <h3>{{ 'naslovna_apartmani_spisak.apartman_5_title' | translate  }}
                                    </h3>
                                    <p>{{ 'naslovna_apartmani_spisak.apartman_5_opis' | translate  }}</p>
                                    <span></span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </a>
                   </div>
                   <div class="item">
                      <a  href="#/apartmani/jana_4">
                         <div class="main-tilt">
                            <img class="main-back" src="/assets/images/koprent_slike/jana_4-5/jana_4_500_500.png" alt="">
                            <div class="inner-tilt">
                               <img class="inner-img" src="/assets/images/koprent_slike/jana_4-5/jana_4_500_500.png" alt="">
                               <div class="slide-cont">
                                  <div>
                                    <h3>{{ 'naslovna_apartmani_spisak.apartman_6_title' | translate  }}
                                    </h3>
                                    <p>{{ 'naslovna_apartmani_spisak.apartman_6_opis' | translate  }}</p>
                                    <span></span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </a>
                   </div>
                   <div class="item">
                     <a  href="#/apartmani/jana_5">
                        <div class="main-tilt">
                           <img class="main-back" src="/assets/images/koprent_slike/jana_4-5/jana_5_500_500.png" alt="">
                           <div class="inner-tilt">
                              <img class="inner-img" src="/assets/images/koprent_slike/jana_4-5/jana_5_500_500.png" alt="">
                              <div class="slide-cont">
                                 <div>
                                   <h3>{{ 'naslovna_apartmani_spisak.apartman_7_title' | translate  }}
                                   </h3>
                                   <p>{{ 'naslovna_apartmani_spisak.apartman_7_opis' | translate }}</p>
                                   <span></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </a>
                  </div>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>