import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css'],
})
export class KontaktComponent implements OnInit {

  pitanjeValidationMsg: string = '';

  pitanjeForm = new FormGroup({
    imePrezime: new FormControl(''),
    email: new FormControl(''),
    naslov: new FormControl(''),
    poruka: new FormControl('')
  });

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
  }

  onPitanjeFormSubmit() {
    let valid = true;
    this.pitanjeValidationMsg = '';

    if (localStorage.getItem('lang') === 'sr') {
      if (!this.pitanjeForm.value.imePrezime) {
        this.pitanjeValidationMsg = "Ime i prezime je obavezno polje";
        valid = false;
      }
      if (!this.pitanjeForm.value.poruka) {
        this.pitanjeValidationMsg = "Poruka je obavezna";
        valid = false;
      }

      let naslov = '';
      if (!this.pitanjeForm.value.naslov) {
        naslov = 'Pitanje::' + this.pitanjeForm.value.imePrezime;
      } else {
        naslov = this.pitanjeForm.value.naslov;
      }

      if (valid) {
        let pitanjeEmail = "mailto:info@koprent.com?"+
              "subject=" + naslov +
              "&body=" + this.pitanjeForm.value.poruka + "%0D%0A %0D%0A" + this.pitanjeForm.value.imePrezime + "%0D%0A" + this.pitanjeForm.value.email;
        window.location.href = pitanjeEmail;
        
        this.pitanjeForm.controls.imePrezime.patchValue('');
        this.pitanjeForm.controls.email.patchValue('');
        this.pitanjeForm.controls.naslov.patchValue('');
        this.pitanjeForm.controls.poruka.patchValue('');
      }
    } else if (localStorage.getItem('lang') === 'en') {
      if (!this.pitanjeForm.value.imePrezime) {
        this.pitanjeValidationMsg = "First and last name is mandatory field";
        valid = false;
      }
      if (!this.pitanjeForm.value.poruka) {
        this.pitanjeValidationMsg = "Message is mandatory field";
        valid = false;
      }

      let naslov = '';
      if (!this.pitanjeForm.value.naslov) {
        naslov = 'Question::' + this.pitanjeForm.value.imePrezime;
      } else {
        naslov = this.pitanjeForm.value.naslov;
      }

      if (valid) {
        let pitanjeEmail = "mailto:info@koprent.com?"+
              "subject=" + naslov +
              "&body=" + this.pitanjeForm.value.poruka + "%0D%0A %0D%0A" + this.pitanjeForm.value.imePrezime + "%0D%0A" + this.pitanjeForm.value.email;
        window.location.href = pitanjeEmail;
        
        this.pitanjeForm.controls.imePrezime.patchValue('');
        this.pitanjeForm.controls.email.patchValue('');
        this.pitanjeForm.controls.naslov.patchValue('');
        this.pitanjeForm.controls.poruka.patchValue('');
      }
    }
  }
}
