<header>
   <div class="header-inner hi-single-yacht mb-0">
     <div #owl class="single-yacht owl-carousel  owl-theme">
         <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5509.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5516.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5530.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5533.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5540.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5543.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5552.jpg">
         </div>
          <div class="item">
            <img src="assets/images/koprent_slike/kopaonik/IMG_5554.jpg">
         </div>
     </div>
     <div class="header-cont">
       <div class="wow fadeInDown single-yacht_dtails" data-wow-delay=".9s">
             <h1 class="y-heading">Kopaonik</h1> <br><br>
             <div>
                  <p>{{ 'kopaonik.spec_1' | translate }}</p>
                  <p><span class="value">{{ 'kopaonik.spec_1_v' | translate }}</span></p> <br>
                  <p>{{ 'kopaonik.spec_2' | translate }}</p>
                  <p><span class="value">{{ 'kopaonik.spec_2_v' | translate }}</span></p> <br>
                  <p>{{ 'kopaonik.spec_3' | translate }}</p>
                  <p><span class="value">{{ 'kopaonik.spec_3_v' | translate }}</span></p> <br>
                  <p>{{ 'kopaonik.spec_4' | translate }}</p>
                  <p><span class="value">{{ 'kopaonik.spec_4_v' | translate }}</span></p> <br>
                  <p>{{ 'kopaonik.spec_5' | translate }}</p>
                  <p><span class="value">{{ 'kopaonik.spec_5_v' | translate }}</span></p> <br>
                  <p>{{ 'kopaonik.spec_6' | translate }}</p>
                  <p><span class="value">{{ 'kopaonik.spec_6_v' | translate }}</span></p> <br>
             </div> 
       </div>

    </div>
    
   </div> 

</header>

<main class="y-inner_page ">
   <section class="y-section_inner y-single_details ">
     <div class="container-fluid">
       <div class="row m-0">
         <div class="col-md-12">
           <div id="accordion">
               <div class="card">
                 <div class="card-header">
                   <a class="card-link no_scroll_f" data-toggle="collapse" href="#collapseOne">
                     <h2><span class="ti-anchor"></span> {{ 'kopaonik.kopaonik_title' | translate }}</h2> 
                     <span class="card_view">Smanji</span>
                   </a>
                 </div>
                 <div id="collapseOne" class="collapse show" data-parent="#accordion">
                   <div class="card-body">
                     <p>{{ 'kopaonik.kopaonik_tekst_1' | translate }}</p>
                     <p>{{ 'kopaonik.kopaonik_tekst_2' | translate }}</p>
                   </div>
                 </div>
               </div>
               <div class="card">
                 <div class="card-header">
                   <a class="collapsed card-link no_scroll_f" data-toggle="collapse" href="#collapseTwo">
                     <h2><span class="ti-anchor"></span> {{ 'kopaonik.ski_centar_title' | translate }}</h2> 
                     <span class="card_view">View More</span>
                   </a>
                 </div>
                 <div id="collapseTwo" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                     <p>{{ 'kopaonik.ski_centar_tekst_1' | translate }}</p>
                     <p>{{ 'kopaonik.ski_centar_tekst_2' | translate }}</p>
                     <p>{{ 'kopaonik.ski_centar_tekst_3' | translate }}</p>
                   </div>
                 </div>
               </div>
               <div class="card">
                 <div class="card-header">
                   <a class="collapsed card-link no_scroll_f" data-toggle="collapse" href="#collapseThree">
                     <h2><span class="ti-anchor"></span> {{ 'kopaonik.aktivnosti_title' | translate }}</h2> 
                     <span class="card_view">View More</span>
                   </a>
                 </div>
                 <div id="collapseThree" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                     <p>{{ 'kopaonik.aktivnosti_tekst' | translate }}</p>
                   </div>
                 </div>
               </div>
               <div class="card">
                 <div class="card-header">
                   <a class="collapsed card-link no_scroll_f" data-toggle="collapse" href="#collapseFour">
                     <h2><span class="ti-anchor"></span> {{ 'kopaonik.manifestacije_title' | translate }}</h2> 
                     <span class="card_view">View More</span>
                   </a>
                 </div>
                 <div id="collapseFour" class="collapse" data-parent="#accordion">
                   <div class="card-body">
                     <p>{{ 'kopaonik.manifestacije_tekst_1' | translate }}</p>
                     <ul>
                        <li>{{ 'kopaonik.manifestacije_1' | translate }}</li>
                        <li>{{ 'kopaonik.manifestacije_2' | translate }}</li>
                        <li>{{ 'kopaonik.manifestacije_3' | translate }}</li>
                        <li>{{ 'kopaonik.manifestacije_4' | translate }}</li>
                        <li>{{ 'kopaonik.manifestacije_5' | translate }}</li>
                     </ul>
                   </div>
                 </div>
               </div>
             </div>
         </div>
       </div>
    </div>  
   </section>
</main> 