import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-jana_5',
  templateUrl: './jana_5.component.html',
  styleUrls: ['./jana_5.component.css'],
})
export class Jana5Component implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
          width: '100%',
          height: '800px',
          thumbnailsColumns: 6,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      {
          breakpoint: 400,
          preview: false
      }
    ];

    this.galleryImages = [
      {
         small: '/assets/images/koprent_slike/jana_4-5/PPH_9597.jpg',
        medium: '/assets/images/koprent_slike/jana_4-5/PPH_9597.jpg',
           big: '/assets/images/koprent_slike/jana_4-5/PPH_9597.jpg'
        },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9612.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9612.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9612.jpg'
         },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9650.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9650.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9650.jpg'
         },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9656.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9656.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9656.jpg'
         },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9658.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9658.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9658.jpg'
         },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9669.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9669.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9669.jpg'
         },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9671.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9671.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9671.jpg'
         },
         {
          small: '/assets/images/koprent_slike/jana_4-5/PPH_9674.jpg',
         medium: '/assets/images/koprent_slike/jana_4-5/PPH_9674.jpg',
            big: '/assets/images/koprent_slike/jana_4-5/PPH_9674.jpg'
         },
    ];
  }
}
