<main>
   <div class="header-home-2">
      <div class="container-fluid">
         <div class="header-cont">
            <h1 class="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">{{ 'cenovnik.title_1' | translate }}</h1>
            <h5 class="wow fadeInUp" data-wow-duration="1s" class="wow fadeInUp" style="color: #aaa;">{{ 'cenovnik.title_2' | translate }}</h5>
         </div>
       </div>
   </div>

   <!-- Bukiranje -->
   <section class="swip h2-book-section" style="background-image: url('/assets/images/koprent_slike/vikend_naselje/CA9A8183_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
      <div class="container-fluid section-menu ">
         <div class="row">
            <div class="col-md-5 home-about wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
               <div class="thm-h ">
                  <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'naslovna_cenovnik.cenovnik_title' | translate }}</h2>
                  <h4>{{ 'naslovna_cenovnik.cenovnik_title_2' | translate }}</h4>
               </div>
               <table style="text-align: center; vertical-align: middle;">
                  <tr>
                     <td colspan="2">{{ 'naslovna_cenovnik.boravak_title' | translate }}</td>
                     <td colspan="4">{{ 'naslovna_cenovnik.smestajna_jedinica_title' | translate }}</td>
                  </tr>
                  <tr>
                     <td style="width: 20%;vertical-align: middle;">{{ 'naslovna_cenovnik.od' | translate }}</td>
                     <td style="width: 20%;vertical-align: middle;">{{ 'naslovna_cenovnik.do' | translate }}</td>
                     <td style="width: 15%;vertical-align: middle;">{{ 'naslovna_cenovnik.studio' | translate }}</td>
                     <td style="width: 15%;vertical-align: middle;">{{ 'naslovna_cenovnik.apartman_4_osobe' | translate }}</td>
                     <td style="width: 15%;vertical-align: middle;">{{ 'naslovna_cenovnik.apartman_pogled_4_osobe' | translate }}</td>
                     <td style="width: 15%;vertical-align: middle;">{{ 'naslovna_cenovnik.apartman_5_7_osoba' | translate }}</td>
                  </tr>
                  <tr>
                     <td>16.03.2021</td>
                     <td>08.12.2021</td>
                     <td>30</td>
                     <td>35</td>
                     <td>40</td>
                     <td>50</td>
                  </tr>
                  <tr>
                     <td>09.12.2021</td>
                     <td>12.12.2021</td>
                     <td>40</td>
                     <td>50</td>
                     <td>55</td>
                     <td>65</td>
                  </tr>
                  <tr>
                     <td>13.12.2021</td>
                     <td>19.12.2021</td>
                     <td>30</td>
                     <td>35</td>
                     <td>40</td>
                     <td>50</td>
                  </tr>
                  <tr>
                     <td>20.12.2021</td>
                     <td>27.12.2021</td>
                     <td>45</td>
                     <td>60</td>
                     <td>70</td>
                     <td>90</td>
                  </tr>
                  <tr>
                     <td>28.12.2021</td>
                     <td>02.01.2022</td>
                     <td>90</td>
                     <td>120</td>
                     <td>130</td>
                     <td>150</td>
                  </tr>
                  <tr>
                     <td>03.01.2022</td>
                     <td>20.01.2022</td>
                     <td>60</td>
                     <td>80</td>
                     <td>100</td>
                     <td>120</td>
                  </tr>
                  <tr>
                     <td>21.01.2022</td>
                     <td>05.02.2022</td>
                     <td>50</td>
                     <td>70</td>
                     <td>90</td>
                     <td>110</td>
                  </tr>
                  <tr>
                     <td>06.02.2022</td>
                     <td>12.02.2022</td>
                     <td>60</td>
                     <td>80</td>
                     <td>100</td>
                     <td>120</td>
                  </tr>
                  <tr>
                     <td>13.02.2022</td>
                     <td>17.02.2022</td>
                     <td>80</td>
                     <td>110</td>
                     <td>120</td>
                     <td>130</td>
                  </tr>
                  <tr>
                     <td>18.02.2022</td>
                     <td>27.02.2022</td>
                     <td>60</td>
                     <td>80</td>
                     <td>100</td>
                     <td>120</td>
                  </tr>
                  <tr>
                     <td>28.02.2022</td>
                     <td>15.03.2022</td>
                     <td>45</td>
                     <td>60</td>
                     <td>70</td>
                     <td>90</td>
                  </tr>
                  <tr>
                     <td>16.03.2022</td>
                     <td>31.03.2022</td>
                     <td>35</td>
                     <td>40</td>
                     <td>45</td>
                     <td>55</td>
                  </tr>
                  <tr>
                     <td>01.04.2022</td>
                     <td>01.12.2022</td>
                     <td>30</td>
                     <td>35</td>
                     <td>40</td>
                     <td>50</td>
                  </tr>
               </table>

               <p>
                  <small>* {{ 'naslovna_cenovnik.cenovnik_info' | translate }}</small>
               </p>
            </div>
            <div class="col-md-2">
            </div>
            <div class="col-md-5  wow fadeInUp" style="padding-top: 64px;">
               <p><b>{{ 'naslovna_cenovnik.popust_na_boravak_title' | translate}}</b></p>
               <ul>
                  <li>{{ 'naslovna_cenovnik.popust_3_5' | translate}}</li>
                  <li>{{ 'naslovna_cenovnik.popust_5_7' | translate}}</li>
                  <li>{{ 'naslovna_cenovnik.popust_7_15' | translate}}</li>
               </ul>
               <p style="padding-top: 32px;"><b>{{ 'naslovna_cenovnik.ostale_informacije' | translate}}</b></p>
               <ul>
                  <li>{{ 'naslovna_cenovnik.ostale_informacije_1' | translate}}</li>
                  <li>{{ 'naslovna_cenovnik.ostale_informacije_2' | translate}}</li>
               </ul>
               <p style="padding-top: 32px;"><b>{{ 'naslovna_cenovnik.ostale_info_rezervacije_title' | translate}}</b></p>
               <ul>
                  <li>{{ 'naslovna_cenovnik.ostale_informacije_3' | translate}}</li>
                  <li>{{ 'naslovna_cenovnik.ostale_informacije_4' | translate}}</li>
                  <li>{{ 'naslovna_cenovnik.ostale_informacije_5' | translate}}</li>
                  <li>{{ 'naslovna_cenovnik.ostale_informacije_6' | translate}}</li>
               </ul>
            </div>
         </div>
      </div>
   </section>
    
    <section id="h-our-history-2" class="half-section main-section h-our-history-3 swip" style="padding-top: 32px; background-image: url('/assets/images/koprent_slike/vikend_naselje/CA9A8183_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row ">
             <div class="col-sm-6 half-left jumbotron" style="background: url('/assets/images/koprent_slike/cenovnik/cenovnik_1.jpg') no-repeat center;
                background-size: cover;"
                data-paroller-factor="-0.3"
                data-paroller-factor-xs="0.2" >
             </div>
             <div class="col-sm-6 half-right">
                <div class="writing-sec my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="horizontal">
                   <div class="thm-h">
                      <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'cenovnik.godisnji_najam_title' | translate }}</h2>
                   </div>
                   <p class="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                     {{ 'cenovnik.godisnji_najam_1' | translate }} <br>
                     {{ 'cenovnik.godisnji_najam_2' | translate }} <br>
                     {{ 'cenovnik.godisnji_najam_3' | translate }} <br>
                   </p>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section id="h-buy" class="half-section main-section swip" style="background-image: url('/assets/images/koprent_slike/vikend_naselje/CA9A8183_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row m-0">
             <div class="col-sm-5 half-left">
                <div class="img-sec  mega my-element"
                   data-paroller-factor="0.05"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal" >
                   <img class="img-fluid" src="/assets/images/koprent_slike/cenovnik/cenovnik_2.jpg">
                </div>
             </div>
             <div class="col-sm-7 half-right">
                <div class="writing-sec mega my-element"
                   data-paroller-factor="-0.2"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal">
                   <div class="thm-h">
                      <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'cenovnik.najam_na_upit_title' | translate }}</h2>
                   </div>
                   <p class="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                     {{ 'cenovnik.najam_na_upit_1' | translate }} <br>
                     {{ 'cenovnik.najam_na_upit_2' | translate }} <br> 
                     {{ 'cenovnik.najam_na_upit_3' | translate }} <br>
                     {{ 'cenovnik.najam_na_upit_4' | translate }} <br>
                   </p>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section id="h-sell" class="half-section main-section swip" style="background-image: url('/assets/images/koprent_slike/vikend_naselje/CA9A8183_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
       <div class="container-fluid">
          <div class="row m-0 rtl-sec">
             <div class="col-sm-5 half-right">
                <div class="img-sec  mega my-element"
                   data-paroller-factor="0.03"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal">
                   <img class="img-fluid" src="/assets/images/koprent_slike/cenovnik/cenovnik_3.jpg">
                </div>
             </div>
             <div class="col-sm-7 half-left ">
                <div class="writing-sec mega my-element"
                   data-paroller-factor="-0.05"
                   data-paroller-factor-lg="0.4"
                   data-paroller-factor-md="0.3"
                   data-paroller-factor-sm="-0.2"
                   data-paroller-factor-xs="-0.1"
                   data-paroller-type="foreground"
                   data-paroller-direction="horizontal" >
                   <div class="thm-h">
                      <h2>{{ 'cenovnik.u_cenu_ukljuceno_title' | translate }}</h2>
                   </div>
                   <p>
                      {{ 'cenovnik.u_cenu_ukljuceno_1' | translate }} <br>
                      {{ 'cenovnik.u_cenu_ukljuceno_2' | translate }} <br>
                      {{ 'cenovnik.u_cenu_ukljuceno_3' | translate }} <br>
                   </p>
                </div>
             </div>
          </div>
       </div>
    </section>

    <section id="h-our-history-2" class="half-section main-section h-our-history-3 swip" style="background-image: url('/assets/images/koprent_slike/vikend_naselje/CA9A8183_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
      <div class="container-fluid">
         <div class="row ">
            <div class="col-sm-6 half-left jumbotron" style="background: url('/assets/images/koprent_slike/cenovnik/cenovnik_4.jpg') no-repeat center;
               background-size: cover;"
               data-paroller-factor="-0.3"
               data-paroller-factor-xs="0.2" >
            </div>
            <div class="col-sm-6 half-right">
               <div class="writing-sec my-paroller" data-paroller-factor="0.3" data-paroller-type="foreground" data-paroller-direction="horizontal">
                  <div class="thm-h">
                     <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'cenovnik.u_cenu_nije_ukljuceno_title' | translate }}</h2>
                  </div>
                  <p class="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                    {{ 'cenovnik.u_cenu_nije_ukljuceno_1' | translate }} <br>
                    <label style="margin-left: 16px;">{{ 'cenovnik.u_cenu_nije_ukljuceno_1_a' | translate }} </label> <br>
                    <label style="margin-left: 16px;">{{ 'cenovnik.u_cenu_nije_ukljuceno_1_b' | translate }} </label> <br>
                    <label style="margin-left: 16px;">{{ 'cenovnik.u_cenu_nije_ukljuceno_1_c' | translate }} </label> <br>
                    {{ 'cenovnik.u_cenu_nije_ukljuceno_2' | translate }} <br><br>
                    {{ 'cenovnik.u_cenu_nije_ukljuceno_3' | translate }} <br>
                  </p>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section id="h-buy" class="half-section main-section swip" style="background-image: url('/assets/images/koprent_slike/vikend_naselje/CA9A8183_dim.jpg'); background-attachment: fixed; background-size: cover; background-position: center bottom;">
      <div class="container-fluid">
         <div class="row m-0">
            <div class="col-sm-5 half-left">
               <div class="img-sec  mega my-element"
                  data-paroller-factor="0.05"
                  data-paroller-factor-lg="0.4"
                  data-paroller-factor-md="0.3"
                  data-paroller-factor-sm="-0.2"
                  data-paroller-factor-xs="-0.1"
                  data-paroller-type="foreground"
                  data-paroller-direction="horizontal" >
                  <img class="img-fluid" src="/assets/images/koprent_slike/cenovnik/cenovnik_5.jpg">
               </div>
            </div>
            <div class="col-sm-7 half-right">
               <div class="writing-sec mega my-element"
                  data-paroller-factor="-0.2"
                  data-paroller-factor-lg="0.4"
                  data-paroller-factor-md="0.3"
                  data-paroller-factor-sm="-0.2"
                  data-paroller-factor-xs="-0.1"
                  data-paroller-type="foreground"
                  data-paroller-direction="horizontal">
                  <div class="thm-h">
                     <h2 class="wow fadeInUp" data-wow-duration="0.7s">{{ 'cenovnik.dodatne_usluge_title' | translate }}</h2>
                  </div>
                  <p class="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                    <label> {{ 'cenovnik.dodatne_usluge_1' | translate }} </label> <br>
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_2' | translate }} </label> <br> 
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_3' | translate }} </label> <br>
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_4' | translate }} </label> <br>
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_5' | translate }} </label> <br>
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_6' | translate }} </label> <br>
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_7' | translate }} </label> <br>
                    <label style="margin-left: 16px;"> {{ 'cenovnik.dodatne_usluge_8' | translate }} </label> <br>
                  </p>
               </div>
            </div>
         </div>
      </div>
   </section>
 </main>