<header>
   <div class="header-inner hi-about-us mb-0">
       <div class="header-cont container-fluid">
         <div class="y-breadcrum clearfix wow fadeInDown " data-wow-delay=".9s">
               <h1 class="y-heading">{{ 'kontakt.kontakt_title' | translate }}</h1> 
               <br>
               <br>
               <br>
         </div>
      </div>
   </div> 
</header>
<main class="y-inner_page y-contact_01">
   <section id="y-single_info">
     <div class="y-single_info">
       <div class="container-fluid">
           <div class="y-single_info_inner y-section_content">
                   <div class="y-contact"> 
                         <div class="row m-0">
                           <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                               <div class="y-contact_inner">
                                   <h3><i class="material-icons">perm_phone_msg</i> {{ 'kontakt.kontaktirajte_nas' | translate }}</h3>
                                   <br>
                                   <br>
                                   <address>
                                       <span>{{ 'kontakt.email' | translate }}: </span> <a target="_self" href="mailto:info@koprent.com" style="margin-left: 24px;"> info@koprent.com</a><br>
                                   </address> 
                                   <address>
                                       <span title="Phone">{{ 'kontakt.telefon' | translate }}: </span> <a href="tel:+381605990933" style="margin-left: 8px;"> +381 60 599 0 933</a>
                                       <br>
                                   </address>
                               </div>
                           </div>
                           <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"> 
                               <div class="y-contact_inner">
                                   <h3><i class="material-icons">business_center</i> {{ 'footer.info_o_firmi' | translate }}</h3>
                                   <br>
                                   <br>
                                   <ul>
                                       <li><span>{{ 'kontakt.info_1' | translate }}</span></li>
                                       <li><span>{{ 'kontakt.info_2' | translate }}</span></li>
                                       <li><span>{{ 'kontakt.info_3' | translate }}</span></li>
                                       <li><span>{{ 'kontakt.info_4' | translate }}</span></li>
                                       <li><span>{{ 'kontakt.info_5' | translate }}</span></li>
                                       <li><span>{{ 'kontakt.info_6' | translate }}</span></li>
                                   </ul>
                               </div>
                           </div>
                           <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"> 
                               <div class="y-contact_inner">
                                   <h3><i class="material-icons">announcement</i> {{ 'kontakt.potrebna_pomoc' | translate }}</h3>
                                   <br>
                                   <br>
                                   <form [formGroup]="pitanjeForm" (ngSubmit)="onPitanjeFormSubmit()" class="y-form" action="#">
                                       <input type="text" formControlName="imePrezime" placeholder="{{ 'kontakt.ime_prezime' | translate}}">
                                       <input type="email" formControlName="email" placeholder="{{ 'kontakt.email_adresa' | translate}}">
                                       <input type="text" formControlName="naslov" placeholder="{{ 'kontakt.naslov_poruke' | translate}}">
                                       <textarea formControlName="poruka" placeholder="{{ 'kontakt.poruka' | translate}}"></textarea>
                                       <label *ngIf="pitanjeValidationMsg" style="color: tomato; margin: 16px;">{{ pitanjeValidationMsg }}</label>
                                       <button class="y-button" type="submit">{{ 'kontakt.posalji_btn' | translate}}</button>
                                   </form>
                               </div>
                           </div>
                         </div>  
                   </div>
           </div>
       </div>
     </div>
   </section>  
   <div class="y-map">
      <iframe
              width="100%"
              height="100%"
              frameborder="0" 
              style="border:0" 
              allowfullscreen
              loading="lazy"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA-bQ_Ds_hPC70aCspiB6ceADyK-_XYA1M
                &q=KopRent+Apartmani+4****,+Рашка">
                <!-- &q=43.275189, 20.759989"> -->
            </iframe>
        
   </div>
 </main>