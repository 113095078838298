import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  pitanjeValidationMsg: string = '';

  pitanjeForm = new FormGroup({
    imePrezime: new FormControl(''),
    poruka: new FormControl('')
  });

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
  }

  onPitanjeFormSubmit() {
    let valid = true;
    this.pitanjeValidationMsg = '';

    if (localStorage.getItem('lang') === 'sr') {
      if (!this.pitanjeForm.value.imePrezime) {
        this.pitanjeValidationMsg = "Ime i prezime je obavezno polje";
        valid = false;
      }
      if (!this.pitanjeForm.value.poruka) {
        this.pitanjeValidationMsg = "Poruka je obavezna";
        valid = false;
      }

      if (valid) {
        let pitanjeEmail = "mailto:info@koprent.com?"+
            "subject=Pitanje::" + this.pitanjeForm.value.imePrezime+
            "&body=" + this.pitanjeForm.value.poruka;
        window.location.href = pitanjeEmail;
        
        this.pitanjeForm.controls.imePrezime.patchValue('');
        this.pitanjeForm.controls.poruka.patchValue('');
      }
    } else if (localStorage.getItem('lang') === 'en') {
      if (!this.pitanjeForm.value.imePrezime) {
        this.pitanjeValidationMsg = "Name and last name are required";
        valid = false;
      }
      if (!this.pitanjeForm.value.poruka) {
        this.pitanjeValidationMsg = "Message is mandatory";
        valid = false;
      }

      if (valid) {
        let pitanjeEmail = "mailto:info@koprent.com?"+
            "subject=Pitanje::" + this.pitanjeForm.value.imePrezime+
            "&body=" + this.pitanjeForm.value.poruka;
        window.location.href = pitanjeEmail;
        
        this.pitanjeForm.controls.imePrezime.patchValue('');
        this.pitanjeForm.controls.poruka.patchValue('');
      }
    }
  }

  promeniJezik(lang: string) {
    this.localStorageService.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
  }
}
