import { LocalStorageService } from './../../services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
  }

  promeniJezik(lang: string) {
    this.localStorageService.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
  }

  goToMenu(menu: string) {
    switch(menu) {
      case 'naslovna': {
        window.location.href = '';
      } break;
      case 'apartmani': {
        window.location.href = '#/apartmani';
      } break;
      case 'cenovnik': {
        window.location.href = '#/cenovnik';
      } break;
      case 'kopaonik': {
        window.location.href = '#/kopaonik';
      } break;
      case 'kontakt': {
        window.location.href = '#/kontakt';
      } break;
    }

  }
}
