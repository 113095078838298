<header>
    
    <div id="mainnavbar" class="container-fluid p-0 menu-2 "  >
       <div class=" fixed-top  container-fluid">
          <div class="row menu-1-inner justify-content-between"  >
             <div class="col-auto menu-1-logo">
                <a class="navbar-brand" href="/">
                <img class="nb-light" src="assets/images/logo.png">
                <span style="margin-left: 16px;">KOPRENT</span>
                </a>
             </div>
             <div class="col-md-7 main_nav_outer">
                <nav class="main_nav">
                   <div class="dropdown">
                      <button type="button" (click)="goToMenu('naslovna')" class="btn btn-primary">{{ 'header.menu_naslovna' | translate }}</button>
                   </div>
                   <div class="dropdown">
                    <button type="button" (click)="goToMenu('apartmani')" class="btn btn-primary">{{ 'header.menu_apartmani' | translate }}</button>
                 </div>
                 <div class="dropdown">
                    <button type="button" (click)="goToMenu('cenovnik')" class="btn btn-primary">{{ 'header.menu_cenovnik' | translate }}</button>
                 </div>
                 <div class="dropdown">
                    <button type="button" (click)="goToMenu('kopaonik')" class="btn btn-primary">{{ 'header.menu_kopaonik' | translate }}</button>
                 </div>
                 <div class="dropdown">
                    <button type="button" (click)="goToMenu('kontakt')" class="btn btn-primary">{{ 'header.menu_kontakt' | translate }}</button>
                 </div>
                </nav>
             </div>
             <div class="col-auto nav-search">
                <div class="login_but">
                    <a href="#">
                       <i class="ti-world"></i>
                    </a>
                    <div class="cart_index">
                      <h5><a href="cart.html">{{ 'header.izaberi_jezik' | translate }}</a></h5>
                      <ul>
                         <li>
                            <span class="cart_porductname" (click)="promeniJezik('sr')" style="cursor: pointer;">{{ 'header.jezik_sr' | translate }}</span>
                         </li>
                         <li>
                            <span class="cart_porductname" (click)="promeniJezik('en')" style="cursor: pointer;">{{ 'header.jezik_en' | translate }}</span>
                         </li>
                      </ul>
                    </div>
                 </div>
                <span class=" col-toggler-2">
                   <button class="navbar-toggler collapsed" type="button" onclick="openNav()" >
                      <div class="hamburger hamburger--spring js-hamburger">
                         <div class="hamburger-box">
                            <div class="hamburger-inner"></div>
                         </div>
                      </div>
                   </button>
                </span>
             </div>
          </div>
          <div  id="mySidenav" class=" navbar-4 sidenav">
             <nav class=" ">
                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                <div class=" ">
                   
                   <div class="sidenav_cat sc_first">
                      <h3>{{ 'side_menu.social' | translate }}</h3>
                      <ul class="tagnav">
                         <li>
                            <a href="https://www.facebook.com/kop.rent.98/?jazoest=24896"><span class=" ti-facebook "></span></a>
                         </li>
                         <li>
                            <a href="https://www.instagram.com/apartmani_koprent/"><span class=" ti-instagram "></span></a>
                         </li>
                      </ul>
                   </div>
                   <div class="sidenav_cat">
                      <h3>{{ 'side_menu.title' | translate }}</h3>
                      <ul class="">
                         <li class="nav-item">
                            <a class="nav-link dropdown-toggle" href="/">{{ 'header.menu_naslovna' | translate }}</a>
                         </li>
                         <li class="nav-item">
                            <a class="nav-link dropdown-toggle" href="#/apartmani">{{ 'header.menu_apartmani' | translate }}</a>
                         </li>
                         <li class="nav-item">
                            <a class="nav-link dropdown-toggle" href="#/cenovnik">{{ 'header.menu_cenovnik' | translate }}</a>
                         </li>
                         <li class="nav-item">
                            <a class="nav-link dropdown-toggle" href="#/kopaonik">{{ 'header.menu_kopaonik' | translate }}</a>
                         </li>
                         <li class="nav-item">
                            <a class="nav-link dropdown-toggle" href="#/kontakt">{{ 'header.menu_kontakt' | translate }}</a>
                         </li>
                      </ul>
                   </div>
                   <div class="sidenav_cat address_slide">
                      <h3>{{ 'side_menu.adresa' | translate }}</h3>
                      <div class="sidenav_cat_inner">
                         <span>
                           Vikend naselje bb<br>
                           36354 Kopaonik<br>
                           Srbija
                         </span>
                      </div>
                      <h3 style="margin-top: 16px;">{{ 'side_menu.firma' | translate }}</h3>
                      <div class="sidenav_cat_inner">
                         <span>
                           "KOPRENT" PR Boris Pantić<br>
                           PIB 107949137<br>
                           Matični broj 63105031<br>
                           Tekući račun: 310-209549-29
                         </span>
                      </div>
                   </div>
                </div>
             </nav>
          </div>
       </div>
    </div>
 </header>
 