import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-marina_27',
  templateUrl: './marina_27.component.html',
  styleUrls: ['./marina_27.component.css'],
})
export class Marina27Component implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
          width: '100%',
          height: '800px',
          thumbnailsColumns: 6,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      {
          breakpoint: 400,
          preview: false
      }
    ];

    this.galleryImages = [
{
 small: '/assets/images/koprent_slike/marina_26/CA9A8241.jpg',
medium: '/assets/images/koprent_slike/marina_26/CA9A8241.jpg',
   big: '/assets/images/koprent_slike/marina_26/CA9A8241.jpg'
},
{
  small: '/assets/images/koprent_slike/marina_26/CA9A8244.jpg',
 medium: '/assets/images/koprent_slike/marina_26/CA9A8244.jpg',
    big: '/assets/images/koprent_slike/marina_26/CA9A8244.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/CA9A8246.jpg',
 medium: '/assets/images/koprent_slike/marina_26/CA9A8246.jpg',
    big: '/assets/images/koprent_slike/marina_26/CA9A8246.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/CA9A8247.jpg',
 medium: '/assets/images/koprent_slike/marina_26/CA9A8247.jpg',
    big: '/assets/images/koprent_slike/marina_26/CA9A8247.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/CA9A8250.jpg',
 medium: '/assets/images/koprent_slike/marina_26/CA9A8250.jpg',
    big: '/assets/images/koprent_slike/marina_26/CA9A8250.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/CA9A8251.jpg',
 medium: '/assets/images/koprent_slike/marina_26/CA9A8251.jpg',
    big: '/assets/images/koprent_slike/marina_26/CA9A8251.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/CA9A8263.jpg',
 medium: '/assets/images/koprent_slike/marina_26/CA9A8263.jpg',
    big: '/assets/images/koprent_slike/marina_26/CA9A8263.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9396.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9396.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9396.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9407.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9407.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9407.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9420.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9420.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9420.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9444.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9444.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9444.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9446.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9446.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9446.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9449.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9449.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9449.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9459.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9459.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9459.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9460.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9460.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9460.jpg'
 },
 {
  small: '/assets/images/koprent_slike/marina_26/PPH_9473.jpg',
 medium: '/assets/images/koprent_slike/marina_26/PPH_9473.jpg',
    big: '/assets/images/koprent_slike/marina_26/PPH_9473.jpg'
 },
    ];
  }
}
