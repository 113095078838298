<main>
    <section class="swip h3-about-section">
       <div class="container-fluid section-menu">
          <div class="row">
             <div class="col-md-8  wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                <div class="home-about-3" style="background-color: rgba(255,255,255, 0.6); padding: 32px;">
                   <div class="thm-h thm-h-3 ">
                      <h2 class=" thm-h-3-big" data-paroller-factor="-0.18" data-paroller-type="foreground" data-paroller-direction="vertical" >{{ 'jana_5.title' | translate }}</h2>
                      <h4>{{ 'jana_4.title' | translate }}</h4>
                   </div>
                   <p>
                    {{ 'jana_5.tekst_1' | translate }}<br>
                    {{ 'jana_5.tekst_2' | translate }}<br>
                    {{ 'jana_5.tekst_3' | translate }}<br>
                    {{ 'jana_5.tekst_4' | translate }}<br>
                    {{ 'jana_5.tekst_5' | translate }}<br>
                    {{ 'jana_5.tekst_6' | translate }}<br>
                   </p>
                </div>
             </div>
             <div class="col-md-3">
             </div>
          </div>
       </div>
    </section>
    
   <section class="main-section explore-section " style="margin-top: 64px;">
      <div class="container-fluid">
         <div class="thm-h sec-main-h">
            <h2 class="wow fadeInUp" data-wow-duration="1s" >{{ 'jana_5.galerija' | translate }}</h2>
         </div>
         <div class="row" style="margin-bottom: 32px;">
            <div class="col-md-12 p-0">
               <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
            </div>
         </div>
      </div>
   </section>
</main>