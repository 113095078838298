<a href="#" id="back-to-top" title="Back to top"><img class="arrow-up " src="assets/images/a.svg"></a>
<div id="main-3">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <div  class="y-loading" id="y-loading">
        <div id="prelouder">
           <img src="assets/images/preloader.gif">
        </div>
     </div>
</div>