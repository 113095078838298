import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-marina_2',
  templateUrl: './marina_2.component.html',
  styleUrls: ['./marina_2.component.css'],
})
export class Marina2Component implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
      let lang = localStorage.getItem('lang');
      if (!lang) {
        localStorageService.setItem('lang', 'sr');
        lang = 'sr';
      }
      translate.setDefaultLang(lang);
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
          width: '100%',
          height: '800px',
          thumbnailsColumns: 6,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      {
          breakpoint: 800,
          width: '100%',
          height: '800px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      {
          breakpoint: 400,
          preview: false
      }
    ];

    this.galleryImages = [
      {
          small: '/assets/images/koprent_slike/marina_2/CA9A8197.jpg',
          medium: '/assets/images/koprent_slike/marina_2/CA9A8197.jpg',
          big: '/assets/images/koprent_slike/marina_2/CA9A8197.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8199.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8199.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8199.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8201.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8201.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8201.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8205.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8205.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8205.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8207.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8207.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8207.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8208.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8208.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8208.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8210.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8210.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8210.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8213.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8213.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8213.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/CA9A8217.jpg',
        medium: 'assets/images/koprent_slike/marina_2/CA9A8217.jpg',
        big: 'assets/images/koprent_slike/marina_2/CA9A8217.jpg'
      },
      {
         small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0018.jpg',
        medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0018.jpg',
           big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0018.jpg'
      },
      {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0019.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0019.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0019.jpg'
     },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0020 - Copy.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0020 - Copy.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0020 - Copy.jpg'
    },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0021.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0021.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0021.jpg'
    },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0022.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0022.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0022.jpg'
    },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0023.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0023.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0023.jpg'
    },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0024.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0024.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0024.jpg'
    },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0027.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0027.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0027.jpg'
    },
    {
        small: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0028.jpg',
       medium: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0028.jpg',
          big: 'assets/images/koprent_slike/marina_2/IMG-20210105-WA0028.jpg'
    },
    ];
  }
}
